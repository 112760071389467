<template>
  <div class="modal-tips-bg">
    <div class="modal-tips">
      <span @click="closeModal" class="close-modal">BACK</span>
      <h1>Instructions</h1>
      <br>
      <h3>Taking screenshots</h3>
      <p><strong>Windows 10: </strong>press keyboard's <span>"PrtSc" / print screen</span> button or use <span>Snipping Tool</span> application (press windows button + type: "snipping tool")</p>
      <p><strong>Mac: </strong>press <span>cmd+shift+4</span> to take screenshot of desired area</p>
      <br>

      <h3>Examples</h3>
      <a href="examples/examples.zip" download>download example files (.zip)</a>
      <br><br>

      <div class="example-pics">
        <div>
          <span>example links</span>
          <img src="examples/example_links.png" alt="example_links.png">
        </div>
        <div>
          <span>example node sizes</span>
          <img src="examples/example_nodes_sizes.png" alt="example_nodes_sizes.png">
        </div>
        <div>
          <span>example node colors</span>
          <img src="examples/example_nodes_colors.png" alt="example_nodes_colors.png">
        </div>
        <div>
          <span>example node labels</span>
          <img src="examples/example_nodes_labels.png" alt="example_nodes_labels.png">
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'modalTips',

  methods: {
    closeModal() {
      this.$store.dispatch('app/SET_MODAL', '')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-tips-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  padding: 3.5rem 1.4rem; // nämä säädetään state.ui:n mukaan myöhemmin!
  background-color: rgba(0, 0, 0, 0.5);
}

$modal-padding: 2rem;

.modal-tips {
  position: relative;
  margin: 0 auto;
  max-width: 650px;
  min-width: 250px;
  padding: $modal-padding;
  padding-top: calc(#{$modal-padding} * 1.2);
  border-radius: 4px;
  background-color: white;
  box-shadow:
    0.5px 1px 3px rgba(0, 0, 0, 0.25),
    0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
  text-align: center;
  .close-modal {
    position: absolute;
    top: calc(#{$modal-padding} * 0.4);
    right: calc(#{$modal-padding} * 0.45);
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
  }
  h1 { margin-bottom: 0; }
  p span {
    color: red;
    font-weight: bold;
  }
  a {
    margin-bottom: 1rem;
    color: red;
    font-weight: bold;
  }
}

.example-pics {
  display: flex;
  justify-content: center;
  //flex-direction: column;
  //align-items: center;
  flex-wrap: wrap;
  div {
    margin: 0.4rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  img {
    margin: 0.3rem 0 0.6rem 0;
    width: 200px;
    border: 2px solid rgba(0, 0, 0, 0.2);
  }
}
</style>